/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Bullet
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
//import * as Constants from '@/utilities/constants';
import Sounds from '@/utilities/sounds';




//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class ShieldBlock extends Phaser.Physics.Arcade.Sprite {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, x, y) {
    //Super
    super(scene, x, y, 'game-assets', 'shield.png');

  }


  //-----------------------------------------------
  // Recycle
  //-----------------------------------------------
  die(reason = '') {
    //Stop
    this.setActive(false);
    this.setVisible(false);

    //Sound
    if(reason === 'enemy') {
      Sounds.play('sfx-thud', {volume:0.1, rate:2});
    }
  }


  //-----------------------------------------------
  // Revive
  //-----------------------------------------------
  revive() {
    this.setActive(true);
    this.setVisible(true);
  }
}
