/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Game Progress / Modifiers
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import * as Constants from '@/utilities/constants';
import Sounds from '@/utilities/sounds';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Brain {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, cookie) {
    //Cookie
    this.scene = scene;
    this.cookie = cookie;

    //Reset
    this.reset();

    //Listen for game events
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }


  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'score') {
      //-----------------
      // Score Event
      //-----------------

      //Update the score
      this.score += data.value;
      this.cookie.scoreLast = this.score; //lazy, but allows us to update DOM HTML elements later

      //Decide if the high-score has been beaten
      if(this.score > this.scoreHigh) {
        
        //Alert if new highscore (and not previously on 0)
        if(!this.scoreHighAlerted) {
          if(this.scoreHigh) {
            console.log('Woot sound!');
          }

          //Flag
          this.scoreHighAlerted = true;
        }

        //Update here
        this.scoreHigh = this.score;

        //Update the player cookie
        this.cookie.saveUser({score:this.scoreHigh});
      }

      //Broadcast an event
      this.broadcastInfo();

      //Check if the enemies are all dead
      if(this.scene.enemies.countActive() === 0) {
        this.levelUp();
      }
    } else if(data.method === 'death') {
      //-----------------
      // Death Event
      //-----------------

      //Reduce the lives
      this.lives = Math.max(0, this.lives - 1);

      //Broadcast a state event (either, death, or game over)
      PubSub.publish('state', {type:'change', id:(this.lives > 0) ? 'game-death' : 'game-over'});

      //Info
      this.broadcastInfo();
     
    } else if(data.method === 'state') {
      if(data.state === 'game-intro') {
        //-----------------
        // Game Intro
        //-----------------

        //Music
        Sounds.music('music-game', { loop: true, volume: 0.1}, true);

        //Wait for a bit, then trigger a new state
        this.scene.time.delayedCall(Constants.GAME_CONFIG.timing.intro, this.stateChange, ['game-on'], this);

        //Reset
        if(data.info === undefined || data.info.levelUp === undefined) {
          this.reset();
        }

        ///Info broadcast
        this.broadcastInfo();
      } else if(data.state === 'game-death') {
        //-----------------
        // Game Death
        //-----------------

        //Wait for a bit, then trigger a new state
        this.scene.time.delayedCall(Constants.GAME_CONFIG.timing.death, this.stateChange, ['game-restart'], this);
      } else if(data.state === 'game-restart') {
        //-----------------
        // Game Restart
        //-----------------

        //Wait for a bit, then trigger a new state
        this.scene.time.delayedCall(Constants.GAME_CONFIG.timing.restart, this.stateChange, ['game-on'], this);
      } else if(data.state === 'game-over') {
        //-----------------
        // Game Over
        //-----------------

        //Stop the music
        Sounds.musicStop();

        //SFX
        Sounds.play('sfx-game-over', {volume:0.5});

        //Wait for a bit, then trigger our final state
        this.scene.time.delayedCall(Constants.GAME_CONFIG.timing.gameOver, this.stateChange, ['game-over-complete'], this);
      } else if(data.state === 'game-wave-complete') {
        //-----------------
        // Game Wave Complete
        //-----------------

        //Stop the music
        Sounds.musicStop();

        //SFX
        Sounds.play('sfx-level-complete', {volume:0.5});

        //Pause then restart the game - issue here is that it resets...
        this.scene.time.delayedCall(Constants.GAME_CONFIG.timing.waveComplete, this.stateChange, ['game-intro', {levelUp:true}], this);
      }
    }
  }


  //-----------------------------------------------
  // Level Up
  //-----------------------------------------------
  levelUp() {
    //Increase the wave
    this.wave++;

    //Modifiers
    this.modifiers.enemySpeedX *= 1.2;
    this.modifiers.enemyShootChance *= 1.2;
    this.modifiers.bulletSpeed.enemy *= 1.1;

    //Event
    PubSub.publish('state', {type:'change', id:'game-wave-complete'});

    //Send the info out
    this.broadcastInfo();
  }


  //-----------------------------------------------
  // State change
  //-----------------------------------------------
  stateChange(id, info = {}) {
    PubSub.publish('state', {type:'change', id:id, info:info});
  }


  //-----------------------------------------------
  // Broadcast event
  //-----------------------------------------------
  broadcastInfo() {
    PubSub.publish('game', this.getInfo({method:'infoUpdate'}));
  }


  //-----------------------------------------------
  // Info
  //-----------------------------------------------
  getInfo(additions = {}) {
    //Base info object
    let info = {
      score:this.score,
      wave:this.wave,
      scoreHigh:this.scoreHigh,
      lives:this.lives,
      modifiers:this.modifiers
    };

    //Additions
    for(let i in additions) {
      info[i] = additions[i]; 
    }

    //Return
    return info;
  }


  //-----------------------------------------------
  // Reset
  //-----------------------------------------------
  reset() {
    //Variables back to defaults
    this.score = 0;
    this.wave = 1;
    this.lives = Constants.GAME_CONFIG.lives;
    this.scoreHigh = (this.cookie.user.score === '') ? 0 : parseInt(this.cookie.user.score);
    this.scoreHighAlerted = false;

    //Modifiers (changed on level progression)
    this.modifiers = {
      enemySpeedX:1,
      enemySpeedY:1,
      enemyShootChance:1,
      bulletSpeed:{player:1, enemy:1}
    };
  }

}
