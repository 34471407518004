/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Enemy Manager
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Elements
import Enemy from '@/objects/game/enemy';

//Utilities
import * as Constants from '@/utilities/constants';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Enemies extends Phaser.Physics.Arcade.Group {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, x, y, brain) {
    //Super
    super(scene.physics.world, scene);

    //Store a reference to the game brain
    this.brain = brain;
   
    //Pubsubs
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });

    //Create
    this.createElements(x, y);

    //Reset
    this.reset();
  }


  //-----------------------------------------------
  // Create the enemies
  //-----------------------------------------------
  createElements(x, y) {

    //Make the bad guys
    let enemy;
    for (let j = 0; j < Constants.GAME_CONFIG.enemyTypes.length; j++) {
      for (let i = 0; i < 7; i++) {
        //Make it
        enemy = this.createFromConfig({
          classType:Enemy,
          key:'enemy',
          setXY:{x:x + i * 48 + (24 * (j%2)), y:y + j * 48},
          active:true,
          visible:true
        });

        //Change
        enemy[0].setup(j, this.brain);

        //Add to physics (do we need to do this?)
        //this.scene.physics.add.existing(butterfly);
        //butterfly.body.setImmovable();
      }
    }
  }


  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'state') {
      if(data.state === 'game-intro') {
        this.reset();
      } else if(data.state === 'game-restart') {
        this.reset(false);
      }
    }
  }


  //-----------------------------------------------
  // Reset
  //-----------------------------------------------
  reset(revive = true) {
    //Offset reset
    this.offset = {
      x:0,
      y:0,
      incX:Constants.GAME_CONFIG.enemyMovement.incX,
      incY:Constants.GAME_CONFIG.enemyMovement.incY,
      maxX:Constants.GAME_CONFIG.enemyMovement.maxX,
      forward:true
    };

    //Items reset
    let children = this.getChildren();
    for(let i = 0; i < children.length; i++) {
      if(revive || children[i].active) {
        children[i].reset();
      }
    }
  }



  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update(state, time) {
    //Change the X position
    this.offset.x += (this.offset.forward) ? this.offset.incX * this.brain.modifiers.enemySpeedX : -this.offset.incX * this.brain.modifiers.enemySpeedX;

    //End?
    if(Math.abs(this.offset.x) > this.offset.maxX) {
      //Down a row, which means we move the other way 
      if(state === 'game-on') {
        this.offset.y += this.offset.incY * this.brain.modifiers.enemySpeedY;
      }

      //Change X
      if(this.offset.forward) {
        this.offsetX = this.offset.maxX;
        this.offset.forward = false;
      } else {
        this.offsetX = -this.offset.maxX;
        this.offset.forward = true;
      }
    }

    //Move them
    let children = this.getChildren();
    for(let i = 0; i < children.length; i++) {
      if(children[i].active) {
        //Move
        children[i].offsetPosition(this.offset.x, this.offset.y);
       
        //Fire if game is on
        if(state === 'game-on') {
          children[i].fire(time);
        }
      }
    }
  }
}
