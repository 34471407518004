/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Scene: Game
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/

//-----------------------------------------------
// Imports
//-----------------------------------------------

//Game Objects
import Bullets from '@/objects/game/bullets';
import Enemies from '@/objects/game/enemies';
import Player from '@/objects/game/player';
import Floor from '@/objects/game/floor';
import Shield from '@/objects/game/shield';
import Messages from '@/objects/game/messages';

//UI
import ScorePanel from '@/objects/game/scorePanel';
import Collisions from '@/objects/game/collisions';
import Particles from '@/objects/game/particles';
import Controls from '@/objects/game/controls';
import Animations from '@/objects/game/animations';
import Brain from '@/objects/game/brain';


//Utilities
import * as Constants from '@/utilities/constants';
import Helper from '@/utilities/helper';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Game extends Phaser.Scene {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Main
    super({key: 'Game'});
  }


  //-----------------------------------------------
  // Preload
  //-----------------------------------------------
  preload() {
    this.load.scenePlugin('VirtualJoystickPlugin', '_plugins/VirtualJoystickPlugin.min.js', 'VirtualJoystickPlugin', 'pad');
    
    //Cover
    this.cover = this.add.graphics(0, 0);
    this.cover.fillStyle(Constants.GAME_CONFIG.preloadColour, 1);
    this.cover.fillRect(0, 0, Constants.GAME_CONFIG.width, Constants.GAME_CONFIG.height);
    this.cover.depth = 1000;
  }



  //-----------------------------------------------
  // Create
  //-----------------------------------------------
  create() {

    //Managers
    this.animations = new Animations(this);
    this.particles = new Particles(this);
    this.brain = new Brain(this, Helper.cookie);

    //PHYSICS BITS
    //http://phaser.io/examples/v3/view/physics/arcade/basic-platform = colliders etc.

    //Base floor level decided on wether we need room for touch controls
    let floorLevel = (!Helper.isTouchDevice()) ? Constants.GAME_CONFIG.height - 15 : Constants.GAME_CONFIG.height - 120;

    //Elements
    this.bullets = new Bullets(this, this.brain);
    this.enemies = new Enemies(this, 64, 90, this.brain);
    this.player = new Player(this, 250, floorLevel - 13);
    this.floor = new Floor(this, 22, floorLevel, 16);
    this.shield = new Shield(this, 39, floorLevel - 75);

    //Score Panel
    this.scores = new ScorePanel(this);

    //Collisions
    this.collisions = new Collisions(this, {
      'enemies':this.enemies,
      'bullets':this.bullets,
      'floor':this.floor,
      'shield':this.shield,
      'player':this.player
    });

    //Messages
    this.messages = new Messages(this, this.brain);

    //Controls
    this.controls = new Controls(this, {showTouchControls:Helper.isTouchDevice()});

    //Events
    this.initComplete();
  }


  //-----------------------------------------------
  // Init Complete
  //-----------------------------------------------
  initComplete() {
    //Game already started (if it took ages to load)
    if(window.state.getState() === 'game-intro') {
      PubSub.publish('game', {method:'state', state:'game-intro', info:{}});
    }

    //Listen for further state events
    PubSub.subscribe('state', (id, data) => { this.stateEvent(data); });

    //Reveal
    this.tweens.add({ targets: this.cover, x:Constants.GAME_CONFIG.width, duration: 300, ease: 'Power2', repeat: 0, delay: 0});

    //Broadcast the current state (causes scoreboard to be upto date)
    this.brain.broadcastInfo();
  }


  //-----------------------------------------------
  // Convert STATE events to GAME events
  //-----------------------------------------------
  stateEvent(data) {
    if(data.type === 'set') {
      PubSub.publish('game', {method:'state', state:data.id, info:data.info || {}});
    }
  }
  


  //-----------------------------------------------
  // Get state
  //-----------------------------------------------
  getState() {
    return window.state.getState();
  }


  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update(time) {
    //Get the state
    let state = this.getState();

    //Process the keys
    this.controls.update();
   
    //Update elements
    this.player.update(state, this.controls.get(), time);
    this.enemies.update(state, time);
  }
}
