/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Utility: Preloader
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
//import * as Cookies from 'js-cookie';
//import Helper from '@/utilities/helper';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Preloader {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Cache
    this.cache = {};

    //Path (no preceding slash is important here to make sure it works in subfolders when deployed)
    this.path = 'assets/_html/';

    //Message
    this.message = $('.loader .message');

    //Preload the images
    this.preloadImages($('body'));
  }



  //-----------------------------------------------
  // Preload images on an element
  //-----------------------------------------------
  preloadImages(element) {
    let images = element.find('.preload[data-image]');
    let image, i, src, type;
    for(i = 0; i < images.length; i++) {
      //Image and src
      image = $(images[i]);
      src = this.path + image.attr('data-image');
      type = 'background';

      //Act
      if(this.cache[src] === undefined) {
        //Store the image
        this.cache[src] = {
          'path':src,
          'elements':[{'item':image, 'type':type}],
          'status':'loading',
          'image':new Image()
        };
        this.cache[src].image.onload = function(src) { this.imageLoaded(src); }.bind(this, src);
        this.cache[src].image.onerror = function(src) { this.imageError(src); }.bind(this, src);

      } else {
        //Image already exists
        if(this.cache[src].status === 'loaded') {
          //Draw
          this.imageDraw(src, image, type);
        } else {
          //Add to elements list
          this.cache[src].elements.push({'item':image, 'type':type});
        }
      }
    }

    //Message
    this.message.text('GET READY...');

    //Load them all
    for(i in this.cache) {
      this.cache[i].image.src = i;
    }
    
  }


  //-----------------------------------------------
  // Image Draw
  //-----------------------------------------------
  imageDraw(src, element, type) {
    if(type === 'background') {
      element.css('background-image', 'url(' + src + ')');
    }
  }


  //-----------------------------------------------
  // Image Loaded
  //-----------------------------------------------
  imageError(src) {
    //Flag
    this.cache[src].status = 'error';

    //Finished
    this.imageLoadCompleteCheck();
  }


  //-----------------------------------------------
  // Image Loaded
  //-----------------------------------------------
  imageLoaded(src) {
    //Flag
    this.cache[src].status = 'loaded';

    //Assign all of the images
    for(let i = 0; i < this.cache[src].elements.length; i++) {
      this.imageDraw(src, this.cache[src].elements[i].item, this.cache[src].elements[i].type);
    }

    //Finished
    this.imageLoadCompleteCheck();
  }


  //-----------------------------------------------
  // Image Load Complete Check
  //-----------------------------------------------
  imageLoadCompleteCheck() {
    //Count
    let count = 0;
    for(let i in this.cache) {
      if(this.cache[i].status === 'loading') {
        count++;
      }
    }

    //Done event
    if(count === 0) { PubSub.publish('preload', {method:'images-complete'}); }
  }
}