/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Utility Object: CRM
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import * as Constants from '@/utilities/constants';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class CRM {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() { 
    //Response callback
    window.crmResponseCatcher = function(data) {
      this.crmResponse(data);
    }.bind(this);
  }


  //-----------------------------------------------
  // Register
  //-----------------------------------------------
  register(name, email, postcode, optin = true) {
    //Data
    let data =  {
      email:email,
      firstname:name,
      //postcode:postcode,
      global_optin:optin //true/false from checkbox
    };

    //Send it
    this.crmSend(data, 'subscribe');
  }


  //-----------------------------------------------
  // Unsubscribe
  //-----------------------------------------------
  unsubscribe(email) {
    this.crmSend({email:email}, 'unsubscribe', 'unsubscribe');
  }


  //-----------------------------------------------
  // CRM Send
  //-----------------------------------------------
  crmSend(data, id, endpointID = 'subscribe') {
    if(Constants.CRM.endpoints[endpointID]) {
      //Constants
      let dataSend = {
        newsletterID:Constants.CRM.newsletterID,
        ref_url:document.location.origin,
        jsonp:'crmResponseCatcher',
      };

      //Extra stuff for gaming etc.
      if(Constants.CRM.dataSource !== undefined) {
        dataSend._ext = Constants.CRM.ext;
        dataSend.datasource =Constants.CRM.dataSource;
      }

      //Merge new data in
      for(let i in data) {
        dataSend[i] = data[i];
      }

      //Store the callback ID and the data we sent
      this.callbackID = id;
      this.sentData = dataSend;

      //Send
      $.ajax({
        type: 'POST',
        url: Constants.CRM.endpoints[endpointID],
        data: dataSend,
        dataType: 'jsonp'
      });
    }
  }


  //-----------------------------------------------
  // CRM Response
  //-----------------------------------------------
  crmResponse(data) {
    PubSub.publish('crm', {id:this.callbackID, response:data, sent:this.sentData});
  }
}