/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Collisions
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import * as Constants from '@/utilities/constants';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Collisions {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, groups) {
    //Scene
    this.scene = scene;
    this.groups = groups;

    //Overlaps
    this.scene.physics.add.overlap(this.groups.bullets, this.groups.bullets, this.collisionBulletBullet, null, this);
    this.scene.physics.add.overlap(this.groups.enemies, this.groups.bullets, this.collisionBulletEnemy, null, this);
    this.scene.physics.add.overlap(this.groups.shield, this.groups.bullets, this.collisionBulletShield, null, this);
    this.scene.physics.add.overlap(this.groups.floor, this.groups.bullets, this.collisionBulletFloor, null, this);
    this.scene.physics.add.overlap(this.groups.floor, this.groups.enemies, this.collisionEnemyFloor, null, this);
    this.scene.physics.add.overlap(this.groups.player, this.groups.enemies, this.collisionEnemyPlayer, null, this);
    this.scene.physics.add.overlap(this.groups.shield, this.groups.enemies, this.collisionEnemyShield, null, this);
    this.scene.physics.add.overlap(this.groups.player, this.groups.bullets, this.collisionBulletPlayer, null, this);
  }


  //-----------------------------------------------
  // Bullet/Bullet Collision (rare and only allowed between player and enemy bullets - does this make it a bit too easy?)
  //-----------------------------------------------
  collisionBulletBullet(bullet1, bullet2) {
    if(bullet1.active && bullet2.active && bullet1.type !== bullet2.type) {
      //Particles
      PubSub.publish('game', {method:'particles', id:'bullet', x:bullet1.x, y:bullet1.y});
      PubSub.publish('game', {method:'particles', id:'bullet', x:bullet2.x, y:bullet2.y});

      //Score
      PubSub.publish('game', {method:'score', value:Constants.GAME_CONFIG.points.bullet});

      //Recycle the bullets
      bullet1.recycle();
      bullet2.recycle();
    }
  }

  //-----------------------------------------------
  // Bullet/Enemy Collision
  //-----------------------------------------------
  collisionBulletEnemy(enemy, bullet) {
    if(bullet.type === 'player' && enemy.active && bullet.active) {
      //Only do most of this if the player is active (stops the player progressing to next level after losing a life)
      if(this.groups.player.active) {
        //Particles
        PubSub.publish('game', {method:'particles', id:'bullet', x:bullet.x, y:bullet.y, explosion:'small'});
        PubSub.publish('game', {method:'particles', id:'enemy' + enemy.subType, x:enemy.x, y:enemy.y, explosion:'big', explosionColour:Constants.GAME_CONFIG.enemyTypes[enemy.subType].colour});

        
        //Kill the enemy
        enemy.die(); 

        //Score
        PubSub.publish('game', {method:'score', value:Constants.GAME_CONFIG.enemyTypes[enemy.subType].points, x:bullet.x, y:bullet.y});       
      }

      //Recycle the bullet regardless
      bullet.recycle();
      
    }
  }


  //-----------------------------------------------
  // Bullet/Player Collision
  //-----------------------------------------------
  collisionBulletPlayer(player, bullet) {
    if(bullet.type === 'enemy' && player.active && bullet.active && this.scene.getState() === 'game-on') {
      //Particles
      PubSub.publish('game', {method:'particles', id:'bullet', x:bullet.x, y:bullet.y});

      //Recycle the bullet
      bullet.recycle();

      //Kill the player
      this.playerDie(player);
    }
  }


  //-----------------------------------------------
  // Bullet/Shield Collision
  //-----------------------------------------------
  collisionBulletShield(shield, bullet) {
    if(shield.active && bullet.active) {
      //Particles
      PubSub.publish('game', {method:'particles', id:'bullet', x:bullet.x, y:bullet.y, explosion:'small'});
      PubSub.publish('game', {method:'particles', id:'shield', x:shield.x, y:shield.y});

      //Player, score a bit
      if(bullet.type === 'player') {
        PubSub.publish('game', {method:'score', value:Constants.GAME_CONFIG.points.shield});
      }

      //Recycle the bullet, remove the shield piece
      bullet.recycle('shield');
      shield.die();
    }
  }


  //-----------------------------------------------
  // Bullet/Floor Collision
  //-----------------------------------------------
  collisionBulletFloor(floor, bullet) {
    if(bullet.active) {
      //Particles
      PubSub.publish('game', {method:'particles', id:'bullet', x:bullet.x, y:bullet.y, explosion:'small'});
      PubSub.publish('game', {method:'particles', id:'floor', x:bullet.x, y:bullet.y + bullet.height / 2});

      //Recycle the bullet
      bullet.recycle('floor');
    }
  }


  //-----------------------------------------------
  // Enemy/Shield Collision
  //-----------------------------------------------
  collisionEnemyShield(shield, enemy) {
    if(enemy.active && shield.active) {
      //Particles
      PubSub.publish('game', {method:'particles', id:'shield', x:shield.x, y:shield.y});

      //Remove the shield piece
      shield.die('enemy');
    }
  }


  //-----------------------------------------------
  // Enemy/Floor Collision
  //-----------------------------------------------
  collisionEnemyFloor(floor, enemy) {
    if(enemy.active && this.groups.player.active && this.scene.getState() === 'game-on') { //this state check is a bit lazy, but stops double events on reset
      //Kill the player
      this.playerDie(this.groups.player);
    }
  }


  //-----------------------------------------------
  // Enemy/Player Collision
  //-----------------------------------------------
  collisionEnemyPlayer(player, enemy) {
    if(enemy.active && player.active && this.scene.getState() === 'game-on') { //this state check is a bit lazy, but stops double events on reset
      //Kill the player
      this.playerDie(player);
    }
  }


  //-----------------------------------------------
  // Player die (called in a few different ways)
  //-----------------------------------------------
  playerDie(player) {
    PubSub.publish('game', {method:'particles', id:'player', x:player.x, y:player.y, explosion:'big'});
    PubSub.publish('game', {method:'death'});
    player.die();
  }
}
