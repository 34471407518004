/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Messaging
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import Helper from '@/utilities/helper';
import * as Constants from '@/utilities/constants';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Messages {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, brain) {
    //Scene
    this.scene = scene;
    this.brain = brain;

    //Text
    this.items = {};

    //Add some messages
    let info = brain.getInfo();
    this.rootPosition = {x:Constants.GAME_CONFIG.width / 2, y:Constants.GAME_CONFIG.height / 2};
    this.addText('game-intro', Constants.GAME_CONFIG.width, this.rootPosition.y, Helper.dictionaryFetch('messages-game-intro', true, info), 36, Constants.COLOURS.green, true);
    this.addText('game-over', Constants.GAME_CONFIG.width, this.rootPosition.y, Helper.dictionaryFetch('messages-game-over', true, info), 36, Constants.COLOURS.red);
    this.addText('game-death', Constants.GAME_CONFIG.width, this.rootPosition.y, Helper.dictionaryFetch('messages-game-death', true, info), 36, Constants.COLOURS.pink);
    this.addText('game-wave-complete', Constants.GAME_CONFIG.width, this.rootPosition.y, Helper.dictionaryFetch('messages-game-wave-complete', true, info), 36, Constants.COLOURS.blue);

    //Listen to events
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }


  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'state') {
      if(data.state === 'game-intro') {
        //GAME INTRO - show wave text
        this.setText('game-intro', Helper.dictionaryFetch('messages-game-intro', true, this.brain.getInfo()));
        this.revealText('game-intro');

        //Remove the wave complete text if it is in
        if(this.items['game-wave-complete'].main.x < Constants.GAME_CONFIG.width) {
          this.moveText('game-wave-complete', -Constants.GAME_CONFIG.width);
        }
      } else if(data.state === 'game-on') {
        //GAME ON - remove the intro text
        this.moveText('game-intro', -Constants.GAME_CONFIG.width);
      } else if(data.state === 'game-over') {
        //GAME OVER
        this.revealText('game-over');
      } else if(data.state === 'game-death') {
        //GAME DEATH
        this.revealText('game-death');
      } else if(data.state === 'game-restart') {
        //GAME RESTART
        this.moveText('game-death', -Constants.GAME_CONFIG.width);
        this.revealText('game-intro');
      } else if(data.state === 'game-over-complete') {
        //GAME OVER COMPLETE
        this.moveText('game-over', -Constants.GAME_CONFIG.width);
      } else if(data.state === 'game-wave-complete') {
        //GAME WAVE COMPLETE
        this.revealText('game-wave-complete');
      }
    }
  }


  //-----------------------------------------------
  // Reveal Text
  //-----------------------------------------------
  revealText(id) {
    //Set offscreen
    this.calculatePositionX(id, Constants.GAME_CONFIG.width, true);

    //Tween it in
    this.moveText(id, 0);
  }


  //-----------------------------------------------
  // Move Text
  //-----------------------------------------------
  moveText(id, x) {
    this.scene.tweens.add({ targets: [this.items[id].main, this.items[id].shadow, this.items[id].highlight], x:this.calculatePositionX(id, x), duration: 300, ease: 'Power2', repeat: 0, delay: 0});
  }


  //-----------------------------------------------
  // Set Text
  //-----------------------------------------------
  setText(id, value) {
    if(this.items[id].main.text !== value) {
      this.items[id].main.text = this.items[id].shadow.text = this.items[id].highlight.text = value;
    }
  }


  //-----------------------------------------------
  // Calculate position
  //-----------------------------------------------
  calculatePositionX(id, x, set = false) {
    //Calculate it
    let value =  this.rootPosition.x + x - Math.round(this.items[id].main.width / 2);

    //Set it
    if(set) {
      this.items[id].main.x = value;
      this.items[id].shadow.x = value;
      this.items[id].highlight.x = value;
    }

    //Return
    return value;
  }


  //-----------------------------------------------
  // Add Text
  //-----------------------------------------------
  addText(id, x, y, value, size, colour, dynamic = false, font = '8BitOperatorPlusBold') {
    //Text Items
    let text, shadow, highlight;
    if(dynamic) {
      shadow = this.scene.add.dynamicBitmapText(x, y + 2, font, value, size).setTint(0x000000).setAlpha(0.8);
      highlight = this.scene.add.dynamicBitmapText(x, y - 2, font, value, size).setTint(0xFFFFFF).setAlpha(0.2);
      text = this.scene.add.dynamicBitmapText(x, y, font, value, size).setTint(colour);
    } else {
      shadow = this.scene.add.bitmapText(x, y + 2, font, value, size).setTint(0x000000).setAlpha(0.8);
      highlight = this.scene.add.bitmapText(x, y - 2, font, value, size).setTint(0xFFFFFF).setAlpha(0.2);
      text = this.scene.add.bitmapText(x, y, font, value, size).setTint(colour);
    }

    //Register
    this.items[id] = {main:text, shadow:shadow, highlight:highlight};

    //Center it
    this.calculatePositionX(id, x, true);
  }
}
