/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Controls
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import * as Constants from '@/utilities/constants';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Controls {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, config) {
    //Scene
    this.scene = scene;

    //Pointer list
    this.pointers = [];

    //Init
    this.initKeys();

    //Buttons
    if(config.showTouchControls) {
      this.initJoystick(0.5, 0.6);
    }
  }


  //-----------------------------------------------
  // Joystick
  //-----------------------------------------------
  initJoystick(scaleStick = 1, scaleButtons = 1) {
    //Edge X
    let edgeX = 130;
    let edgeY = 52;

    //Stick
    this.stick = this.scene.pad.addStick(edgeX, Constants.GAME_CONFIG.height - edgeY, 100, 'arcade');
    this.stick.scale  = scaleStick;

    //Fire Button
    this.button = this.scene.pad.addButton(Constants.GAME_CONFIG.width - edgeX, Constants.GAME_CONFIG.height - edgeY, 'arcade', 'button1-up', 'button1-down');
    this.button.scale  = scaleButtons;

    //Second button (without this, the buttons don't poll correctly when other touches are down)
    //this.button2 = this.scene.pad.addButton(Constants.GAME_CONFIG.width - edgeX * 2, Constants.GAME_CONFIG.height - edgeY, 'arcade', 'button2-up', 'button2-down');
    //this.button2.scale = 0;

    //Lock Motion  
    this.stick.setMotionLock(1);

    //Secondary pointer otherwise button won't work
    this.scene.input.addPointer();
  }



  //-----------------------------------------------
  // Keys
  //-----------------------------------------------
  initKeys() {
    let key, keyData;
    this.keys = {};
    
    for(let i in Constants.GAME_CONFIG.controls) {
      //Data
      keyData = {
        down:false,
        listeners:[]
      };

      //Make a listener for each one
      for(let j = 0; j < Constants.GAME_CONFIG.controls[i].length; j++) {
        key = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes[Constants.GAME_CONFIG.controls[i][j]], false); //false means it still gets sent to the DOM
        keyData.listeners.push(key);
      }

      //Register it
      this.keys[i] = keyData;
    }
  }


  //-----------------------------------------------
  // Get
  //-----------------------------------------------
  get() {
    return this.keys;
  }


  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update() {
    //Keys
    for(let i in this.keys) {
      this.keys[i].down = false;
      for(let j = 0; j < this.keys[i].listeners.length; j++) {
        if(this.keys[i].listeners[j].isDown) {
          this.keys[i].down = true;
          break;
        }
      }
    }

    //Stick
    if(this.stick) {
      //Fire
      if(this.button.isDown) {
        this.keys.fire.down = true;
      }

      //Left/Right
      if(this.stick.direction === 7) {
        this.keys.left.down = true;
      } else if(this.stick.direction === 8) {
        this.keys.right.down = true;
      }
    }
  }

}
