/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Bullet
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
//import * as Constants from '@/utilities/constants';
import Sounds from '@/utilities/sounds';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Bullet extends Phaser.Physics.Arcade.Sprite {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, x, y) {
    //Super
    super(scene, x, y, 'game-assets', 'bullet.png');

    //Type
    this.type = '';
  }


  //-----------------------------------------------
  // Fire a bullet at a given point
  //-----------------------------------------------
  fire(x, y, type, velocity) {
    //Reset to the point
    this.body.reset(x, y);

    //Rotate
    this.setAngle((type === 'player') ? 180 : 0);

    //Active and visible
    this.setActive(true);
    this.setVisible(true);

    //Store the type for collisions
    this.type = type;

    //Move
    this.setVelocityY(velocity);

  }


  //-----------------------------------------------
  // Pre update
  //-----------------------------------------------
  preUpdate (time, delta) {
    //Main
    super.preUpdate(time, delta);

    //Offscreen recycle
    if(this.y < -this.height && this.active) {
      this.recycle();
    }
  }


  //-----------------------------------------------
  // Recycle
  //-----------------------------------------------
  recycle(reason = '') {
    //Stop
    this.setActive(false);
    this.setVisible(false);
    this.setVelocityY(0);

    //Move somewhere sensible
    this.body.reset(0, 0);

    //Reset
    this.type = '';

    //Sound
    if(reason === 'shield') {
      Sounds.play('sfx-thud', {volume:0.05, rate:1});
    } else if(reason === 'floor') {
      Sounds.play('sfx-thud', {volume:0.1, rate:1.5});
    }
  }
}
