/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Floor
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
//import Helper from '@/utilities/helper';
//import Sounds from '@/utilities/sounds';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Floor extends Phaser.Physics.Arcade.StaticGroup  {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, x, y, count) {
    //Super
    super(scene.physics.world, scene);

    //Create
    this.createElements(x, y, count);
  }


  //-----------------------------------------------
  // Create
  //-----------------------------------------------
  createElements(x, y, count) {
    //Tiles
    for (let i = 0; i < count; i++) {
      this.create(x + (i * 29), y, 'game-assets', 'floor.png');
    }
  }


  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update() {}
}
