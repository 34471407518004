/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Score Number
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import * as Constants from '@/utilities/constants';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Explosion extends Phaser.GameObjects.Sprite {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, id = 'spriteID') {
    //Super
    super(scene, 0, 0, 'game-assets', id);

    //Origin
    this.setOrigin(0.5, 0.5);
  }


  //-----------------------------------------------
  // Show
  //-----------------------------------------------
  show(x, y, colour = 'green') {
    this.x = x;
    this.y = y;
    this.setActive(true);
    this.setVisible(true);
    this.setAlpha(1);
    this.setScale(1);
    this.depth = 999;
    this.setTint(Constants.COLOURS[colour]);

    //Tween
    this.scene.tweens.add({ targets: [this], scale:0, alpha:0, duration: 400, ease: 'Power2', repeat: 0, delay: 0, onComplete:function() { this.die(); }.bind(this)});
   
  }

  //-----------------------------------------------
  // Die
  //-----------------------------------------------
  die() {
    this.setActive(false);
    this.setVisible(false);
  }
}


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export class ExplosionBig extends Explosion {
  constructor(scene) {
    super(scene, 'particle2.png');
  }
}


export class ExplosionSmall extends Explosion {
  constructor(scene) {
    super(scene, 'particle1.png');
  }
}