/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Score Panel
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import Helper from '@/utilities/helper';
import * as Constants from '@/utilities/constants';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class ScorePanel {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene) {
    //Add
    this.scene = scene;

    //Frames
    let x = 10;
    let y = 8;
    let spaceX = 170;
    for(let i = 0; i < 3; i++) {
      this.scene.add.sprite(x + (i * spaceX), y, 'game-assets', 'score-frame.png').setOrigin(0, 0);
    }

    //Text
    this.items = {};

    //Labels
    this.addText('lives-label', 10 + 60, y + 7, Helper.dictionaryFetch('panel-label-lives', true), 12, Constants.COLOURS.blue);
    this.addText('score-label', 70 + spaceX, y + 7, Helper.dictionaryFetch('panel-label-score', true), 12, Constants.COLOURS.pink);
    this.addText('hiscore-label', 70 + spaceX * 2, y + 7, Helper.dictionaryFetch('panel-label-hiscore', true), 12, Constants.COLOURS.red);

    //Values
    this.addText('lives-value', 70, y + 18, '0', 24, 0x07e6f7, true);
    this.addText('score-value', 70 + spaceX, y + 18, '0', 24, 0xde11d8, true);
    this.addText('hiscore-value', 70 + spaceX * 2, y + 18, '0', 24, 0xff0000, true);

    //Listen
    this.addPubSubs();
  }


  //-----------------------------------------------
  // Add Text
  //-----------------------------------------------
  addText(id, x, y, value, size, colour, dynamic = false, font = '8BitOperatorPlusBold') {
    //Adjust X, Y
    //x += this.background.x - this.background.width;
    //y += this.background.y;


    //Text Items
    let text;
    if(dynamic) {
      text = this.scene.add.dynamicBitmapText(x, y, font, value, size).setTint(colour);
    } else {
      text = this.scene.add.bitmapText(x, y, font, value, size).setTint(colour);
    }

    //Center
    text.x -= Math.round(text.width / 2);


    //Register
    this.items[id] = {
      main:text,
      x:x,
      y:y
    };
  }


  //-----------------------------------------------
  // Event listeners
  //-----------------------------------------------
  addPubSubs() {
    //State / Game events
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }


  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'infoUpdate') {
      //Progress Event!
      this.setTextValue('score', data.score);
      this.setTextValue('hiscore', data.scoreHigh);
      this.setTextValue('lives', data.lives);


      //Set the score
      //this.setScore(Helper.gameInfo.score);
    }
  }

  //-----------------------------------------------
  // Set a text value
  //-----------------------------------------------
  setTextValue(id, value) {
    value = value + '';
    let item = this.items[id + '-value'];

    //Set if not already
    if(item.main.text !== value) {
      item.main.text = value;
      item.main.x = Math.round(item.x - item.main.width / 2);
    }
  }
}
