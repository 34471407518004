/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Enemy
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import * as Constants from '@/utilities/constants';
import Sounds from '@/utilities/sounds';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Enemy extends Phaser.Physics.Arcade.Sprite {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, x, y) {
    //Super
    super(scene, x, y, 'butterfly');

    //Last fire time
    this.bulletLast = 0;
    this.type = 'enemy';
    this.subType = 0; //colour

    //Add to physics
    scene.physics.add.existing(this);
    this.body.setSize(40, 28);

    //Flap!
    this.play('flap');
  }

  //-----------------------------------------------
  // Fire
  //-----------------------------------------------
  fire(time) {
    if(this.fireTest(time)) {
      //Fire an event to be picked up by the bullet manager
      PubSub.publish('game', { method:'fire', type:'enemy', x:this.x, y:this.y});

      //Store the time
      this.bulletLast = time;
    }
  }


  //-----------------------------------------------
  // Can fire
  //-----------------------------------------------
  fireTest(time) {
    if(this.progress !== undefined) {
      if((Math.random() <  (Constants.GAME_CONFIG.enemyFireChance * this.progress.modifiers.enemyShootChance)) && ((time - this.bulletLast) > Constants.GAME_CONFIG.enemyShootDelay)) {
        return true;
      }
    }
    return false;
  }


  //-----------------------------------------------
  // Set root positions
  //-----------------------------------------------
  setRootPosition() {
    this.root = {x:this.x, y:this.y};
  }


  //-----------------------------------------------
  // Set type
  //-----------------------------------------------
  setup(id, progress) {
    //Progress reference
    this.progress  = progress;

    //Type
    this.subType = id;

    //Colour
    this.setTint(Constants.COLOURS[Constants.GAME_CONFIG.enemyTypes[id].colour]);

    //Root position for movement
    this.setRootPosition();
  }

  //-----------------------------------------------
  // Offset position
  //-----------------------------------------------
  offsetPosition(x, y) {
    if(this.root) {
      this.body.x = this.root.x + x;
      this.body.y = this.root.y + y;
    }
  }


  //-----------------------------------------------
  // Reset
  //-----------------------------------------------
  reset() {
    this.setActive(true);
    this.setVisible(true);
    this.offsetPosition(0, 0);
  }


  //-----------------------------------------------
  // Die
  //-----------------------------------------------
  die() {
    this.setActive(false);
    this.setVisible(false);
    this.setVelocityY(0);

    //SFX
    let id = Math.floor(Math.random() * 2) + 1;
    Sounds.play('sfx-explosion' + id, {volume:0.6});
  }
}
