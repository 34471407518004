/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Utility Object: Constants
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Global
//-----------------------------------------------
export const DEPLOY_MODE = (document.location.href.indexOf(':3000') !== -1) ? 'local' : 'live'; //stops debug mode
export const DEBUG_MODE = (DEPLOY_MODE === 'local') ? true : false;



//-----------------------------------------------
// Cookie
//-----------------------------------------------
export const COOKIE = {
  user:'lbInvadersInfo',
  sound:'lbInvadersSound'
};


//-----------------------------------------------
// Colours
//-----------------------------------------------
export const COLOURS = {
  red:0xff0000,
  pink:0xde11d8,
  blue:0x07e6f7,
  green:0x00FF00,
  black:0x0A0A0A
};


//-----------------------------------------------
// Game Config
//-----------------------------------------------
export const GAME_CONFIG = {
  //Layout
  width:480,
  height:720,
  margin:{x:20, y:20},
  maxScale:3,

  //Timings
  timing:{
    intro:2000, //Wave X text on intro
    death:2000,  //'You Died' text
    restart:1500, //Wave X text after death
    gameOver:2000, //Game Over message
    waveComplete:1500 //Wave complete message
  },

  //Enemies!
  enemyTypes:[
    {colour:'red', points:25}, {colour:'pink', points:20}, {colour:'blue', points:15},
    {colour:'red', points:10}, {colour:'pink', points:5}, {colour:'blue', points:2}
  ],

  //Enemy Movement
  enemyMovement: {
    incX:0.75,
    incY:10,
    maxX:45
  },

  //Points
  points:{bullet:3, shield:1},

  //Controls
  controls : {
    left:['LEFT', 'Z'],
    right:['RIGHT', 'X'],
    fire:['SPACE', 'ENTER', 'C']
  },

  //Others
  preloadColour:COLOURS.black,

  //In Game
  lives:(DEPLOY_MODE === 'local') ? 2 : 3,
  playerSpeed:100,
  playerShootDelay:350, //how fast in MS between shots
  bulletSpeed: {player:250, enemy:150},
  enemyFireChance:0.00075,
  enemyShootDelay:2000,
};


//-----------------------------------------------
// Dictionary
//-----------------------------------------------
export const DICTIONARY = {
  //Character Select
  'panel-label-lives': '1up',
  'panel-label-score': 'Score',
  'panel-label-hiscore': 'Hi-score',

  //Messages
  'messages-game-intro': 'Wave {WAVE}',
  'messages-game-over': 'Game Over',
  'messages-game-death': 'You died!',
  'messages-game-wave-complete': 'Wave Complete!'
};

//-----------------------------------------------
// WARNER CRM BITS
//-----------------------------------------------
export const CRM = {
  'country':'US',
  'newsletterID':14078141,
  //'ext':'5721D423-A398-4B11-8D58-1155968F2AB0:6406001',
  //'dataSource':'Lizzo_FollowToPlayGame_Registration',
  'endpoints':{
    'subscribe':'https://signup.wmg.com/register',
    'unsubscribe':'https://signup.wmg.com/unsubscribe'
  }
};
