/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Animations
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
//import * as Constants from '@/utilities/constants';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Animations {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene) {
    //Scene
    this.scene = scene;

    //Butterfly flap
    this.scene.anims.create({
      key: 'flap',
      frames: 'butterfly',
      frameRate: 20,
      repeat: -1
    });

    //Snap animation on flytrap (put the first frame at the end)
    let frames = this.scene.anims.generateFrameNumbers('flytrap');
    frames.push(frames.splice(0, 1)[0]);
    this.scene.anims.create({
      key: 'snap',
      frames: frames,
      frameRate: 24,
      repeat: 0
    });
  }

}
