/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Bullet Manager
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Elements
import Bullet from '@/objects/game/bullet';

//Utilities
import * as Constants from '@/utilities/constants';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Bullets extends Phaser.Physics.Arcade.Group {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, brain) {
    //Super
    super(scene.physics.world, scene);

    //Progress / Game modifiers
    this.brain = brain;

    //Creat the bullet pool
    this.createMultiple({
      quantity: 50,
      key: 'bullet',
      active: false,
      visible: false,
      classType: Bullet
    });

    //Listener
    this.addPubSubs();
  }


  //-----------------------------------------------
  // Fire it
  //-----------------------------------------------
  fireBullet (x, y, type = 'player') {
    //Get a bullet
    let bullet = this.getFirstDead(false);

    //Fire!
    if (bullet) {
      let velocity = Constants.GAME_CONFIG.bulletSpeed[type] * this.brain.modifiers.bulletSpeed[type];
      bullet.fire(x, y, type, (type === 'player') ? velocity * -1 : velocity);
    }
  }


  //-----------------------------------------------
  // Event listeners
  //-----------------------------------------------
  addPubSubs() {
    //State / Game events
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }


  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'fire') {
      //Fire a bullet
      this.fireBullet(data.x, data.y, data.type);
    }
  }
}

