/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Utility Object: Resize Manager
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/

//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utils
import * as Constants from '@/utilities/constants';
import Helper from '@/utilities/helper';





//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class ResizeManager {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Event listener
    $(window).on('resize orientationchange', function() {
      this.resizeDetected();
    }.bind(this));

    //Elements
    this.gameMaxScale = Constants.GAME_CONFIG.maxScale;
    this.gameMargin = Constants.GAME_CONFIG.margin;
    this.gameSize = {w:Constants.GAME_CONFIG.width, h:Constants.GAME_CONFIG.height};
    this.elements = {
      'game':$('.game-holder'),
      'navigation':$('.navigation')
    };

    //Listen
    this.resizeDo();
  }


  //-----------------------------------------------
  // State Event
  //-----------------------------------------------
  resizeDetected() {
    if(this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    this.resizeTimer = setTimeout(function() { this.resizeDo(); }.bind(this), 200);
  }


  //-----------------------------------------------
  // State Event
  //-----------------------------------------------
  resizeDo() {
    //Size
    let width = $(window).innerWidth();
    let height = $(window).innerHeight();

    //Assuming we are resizing to height
    let gameHeight = Math.min(this.gameMaxScale * this.gameSize.h, height - (this.gameMargin.y * 2));
    let gameWidth = gameHeight * (this.gameSize.w / this.gameSize.h);

    //If this gives us a bad width, then we switch to width instead
    if(gameWidth > width  - (this.gameMargin.x * 2)) {
      gameWidth = Math.min(this.gameMaxScale * this.gameSize.w, width - (this.gameMargin.x * 2));
      gameHeight = gameWidth * (this.gameSize.h / this.gameSize.w);
    }

    //Change the size
    this.elements.game.css('width', Math.floor(gameWidth));
    this.elements.game.css('height', Math.floor(gameHeight));

    //Navigation
    let scale = Math.min(1, width / 640);
    let bottomMargin = (Helper.isTouchDevice()) ? 10 : 30;
    this.elements.navigation.css('right', Math.floor((width - gameWidth) / 2) + (12 * scale));
    this.elements.navigation.css('bottom', Math.floor((height - gameHeight) / 2) + (bottomMargin * scale));
    this.elements.navigation.css('transform', 'scale(' + scale + ')');
  }
}
