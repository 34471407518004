/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Shield
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Elements
import ShieldBlock from '@/objects/game/shieldBlock';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Shield extends Phaser.Physics.Arcade.StaticGroup {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, x, y) {
    //Super
    super(scene.physics.world, scene);

    //Layout
    this.blocks = 4;
    this.layout = [[3, 2], [2, 3], [1, 3], [0, 4], [1, 3], [2, 3], [3, 2]];

    //Create
    this.createElements(x, y);

    //Listen
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }


  //-----------------------------------------------
  // Create the enemies
  //-----------------------------------------------
  createElements(x, y) {
    //Make the bad guys
    for (let i = 0; i < this.blocks; i++) {
      //Make it
      for(let j = 0; j < this.layout.length; j++) {
        for(let k = 0; k < this.layout[j][1]; k++) {
          this.createFromConfig({
            classType:ShieldBlock,
            key:'shield',
            setXY:{x:x, y:y + (k * 10) + (5 * this.layout[j][0])},
            active:true,
            visible:true
          });
        }

        //Inc X
        x += 11;
      }

      //Gap between blocks
      x += 35;
    }
  }

  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'state') {
      if(data.state === 'game-intro') {
        if(data.info === undefined || data.info.levelUp === undefined) {
          this.reset();
        }
      }
    }
  }


  //-----------------------------------------------
  // Reset
  //-----------------------------------------------
  reset() {
    //Move them
    let children = this.getChildren();
    for(let i = 0; i < children.length; i++) {
      children[i].revive();
    }
  }
}
