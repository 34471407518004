/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       State Manager
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/

//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utils
//import Sounds from '@/utilities/sounds';
//import Helper from '@/utilities/helper';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class StateManager {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, initState = 'intro') {
    //Current
    this.scene = scene;
    this.current = '';

    //States for the game
    //  intro                 -   Intro Page (HTML)
    //  form                  -   Register(HTML)
    //  game-intro            -   Showing 'Wave X' messaging
    //  game-wave-complete    -   Showing 'Wave X Complete' messaging
    //  game-restart          -   Called after death to tell them the wave again
    //  game-death            -   Player Died (but has remaining lives else we go to game-over)
    //  game-on               -   Game Playing
    //  game-over             -   Game Over (Display text to tell user game is)
    //  game-over-complete    -   End (HTML)

    //Go
    this.setState(initState);

    //Listen for state change requests (from menu, or automated game functions etc.)
    PubSub.subscribe('state', (id, data) => { this.stateEvent(data); });
  }


  //-----------------------------------------------
  // State Event
  //-----------------------------------------------
  stateEvent(data) {
    if(data.type === 'change') {
      this.setState(data.id, data.info || {} );
    }
  }


  //-----------------------------------------------
  // Set the state
  //-----------------------------------------------
  setState(state, info = {}) {
    if(state !== this.current) {
      //Store the new state
      this.current = state;  

      //Do certain things based on the state we are going to etc.
      /*if(state === 'game-intro') {
      }*/

      //Fire 'state_set' event
      PubSub.publish('state', {type:'set', id:state, info:info});
    }
  }


  //-----------------------------------------------
  // Get the state
  //-----------------------------------------------
  getState() {
    return this.current;
  }
}
