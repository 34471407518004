/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       App Index
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------

//Config
import * as config from '@/config';

//Pluings
import screenfull from 'screenfull';

//CSS
import '@/../styles/app.scss';

//Utils
import * as Constants from '@/utilities/constants';
import StateManager from '@/utilities/stateManager';
import ResizeManager from '@/utilities/resizeManager';
import Helper from '@/utilities/helper';
import Modal from '@/utilities/modals';
import Cookie from '@/utilities/cookie';
import CRM from '@/utilities/crm';
import Preloader from '@/utilities/preloader';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Game {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Clear pubsubs (should only be needed if repeat booted)
    PubSub.clearAllSubscriptions();

    //Debug
    $('body').addClass(Constants.DEPLOY_MODE);

    //Cookie & CRM Handler
    Helper.cookie = new Cookie();
    Helper.crm = new CRM();
    Helper.resizer = new ResizeManager();

    //Modal
    Helper.modal = new Modal($('.modal-wrapper'), Helper.cookie, Helper.crm);

    //Preloader
    Helper.preloader = new Preloader();

    //Listen to preload events
    PubSub.subscribe('preload', (id, data) => { this.preloadEvent(data); });
  }

  //-----------------------------------------------
  // Preload Event
  //-----------------------------------------------
  preloadEvent(data) {
    if(data.method === 'images-complete') {
      this.gameStart();
    }
  }


  //-----------------------------------------------
  // Game Start
  //-----------------------------------------------
  gameStart() {
    //Reveal
    $('body').addClass('loaded').removeClass('loading');
    setTimeout(() => $('body').removeClass('loaded'), 1000);

    //State Manager (assign this to the window so we can check it once loading has finished)
    window.state = new StateManager(this, (Helper.deployMode() === 'local') ? 'intro' : 'intro');

    //Game
    new Phaser.Game(config);

    //Fullscreen
    if (screenfull.isEnabled) {
      $('#button-fullscreen').on('click', () => {
        screenfull.request();
      });
    } else {
      $('#button-fullscreen').remove();
    }
  }
}


//-----------------------------------------------
// Boot
//-----------------------------------------------
$(function() { new Game({}); });