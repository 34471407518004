/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Utility Object: Cookie Management
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import * as Cookies from 'js-cookie';
import * as Constants from '@/utilities/constants';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Cookie {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Data
    this.user = {email:'', name:'', score:'', image: '', type:''}; //user info
    this.sound = '1'; //has user muted sound?

    //Variable last score (not cookied, but held here and updated by the game brain - lazy, but it works)
    this.scoreLast = 0;

    //Restore cookie
    if(Cookies.get(Constants.COOKIE.user) !== undefined) {
      this.user = JSON.parse(Cookies.get(Constants.COOKIE.user));
      
      //Added profile image
      if(this.user.image === undefined) {
        this.user = {email:'', name:'', score:'', image: '', type:''};
      }
    }


    //Restore cookie for sound
    if(Cookies.get(Constants.COOKIE.sound) !== undefined) {
      this.sound = Cookies.get(Constants.COOKIE.sound);
    }
    this.audioCheck();
  }


  //-----------------------------------------------
  // Audio check
  //-----------------------------------------------
  audioCheck() {
    //Howler.mute((this.sound === '1') ? false : true);
  }


  //-----------------------------------------------
  // Audio toggle
  //-----------------------------------------------
  audioToggle(value = '') {
    this.sound = (this.sound === '1') ? '0' : '1';
    if(value !== '') { this.sound = value; }
    this.save(Constants.COOKIE.sound, this.sound);
    this.audioCheck();
  }


  //-----------------------------------------------
  // Store User
  //-----------------------------------------------
  saveUser(data) {
    //Update the local user block
    for(var i in data) {
      if(this.user[i] !== undefined) {
        console.log('Updated', i, data[i]);
        this.user[i] = data[i];
      }
    }

    //Save it to the cookie
    this.save(Constants.COOKIE.user, JSON.stringify(this.user));
  }


  //-----------------------------------------------
  // Store User
  //-----------------------------------------------
  save(id, value, expires = 365) {
    Cookies.set(id, value, { expires: expires });
  }
}