/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Particles / Numbers / Explosions
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Elements
import ScoreNumber from '@/objects/game/scoreNumber';
import {ExplosionBig,ExplosionSmall} from '@/objects/game/explosion';




//Utilities
import * as Constants from '@/utilities/constants';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Collisions {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene) {
    //Scene
    this.scene = scene;
    
    //Group of numbers
    this.initNumbers();

    //Group of explosion sprites
    this.initExplosions();

    //Particles
    this.initParticles();

    //Pubsubs
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }


  //-----------------------------------------------
  // Init Particles
  //-----------------------------------------------
  initParticles() {
    //Particles
    this.particles = this.scene.add.particles('game-assets', 'particle.png');

    //Shield
    this.particles.createEmitter({
      name:'shield',
      angle: { min: 140, max: 400 },
      speed: {min:100, max:250},
      rotate: {min:0, max:360},
      gravityY: 500,
      lifespan: 1500,
      quantity: {min:5, max:7},
      scale: { start: 2, end: 0 },
      tint:[0xFFFFFF],
      on:false
    });

    //Bullet
    this.particles.createEmitter({
      name:'bullet',
      angle: { min: 140, max: 400 },
      speed: {min:100, max:250},
      //rotate: {min:0, max:360},
      gravityY: 500,
      lifespan: 1200,
      quantity: {min:2, max:5},
      scale: { start: 1, end: 0 },
      tint:[Constants.COLOURS.green],
      on:false
    });

    //Floor
    this.particles.createEmitter({
      name:'floor',
      angle: { min: 140, max: 400 },
      speed: {min:100, max:200},
      rotate: {min:0, max:360},
      gravityY: 500,
      lifespan: 1500,
      quantity: {min:3, max:5},
      scale: { start: 2, end: 0 },
      tint:[0xde11d8],
      on:false
    });

    //Player
    this.particles.createEmitter({
      name:'player',
      angle: { min: 140, max: 400 },
      speed: {min:50, max:200},
      rotate: {min:0, max:360},
      gravityY: 100,
      lifespan: 2000,
      quantity: {min:30, max:50},
      scale: { start: 2, end: 0 },
      tint:[Constants.COLOURS.blue, Constants.COLOURS.purple],
      on:false
    });

    //Enemy Types
    for(let i = 0; i < Constants.GAME_CONFIG.enemyTypes.length; i++) {
      this.particles.createEmitter({
        name:'enemy' + i,
        angle: { min: 140, max: 400 },
        speed: {min:50, max:200},
        rotate: {min:0, max:360},
        gravityY: 500,
        lifespan: 1500,
        quantity: {min:6, max:12},
        scale: { start: 2, end: 0 },
        tint:[Constants.GAME_CONFIG.enemyTypes[i].colour],
        on:false
      });
    }
  }


  //-----------------------------------------------
  // Init Numbers
  //-----------------------------------------------
  initNumbers() {
    //Group
    this.numbers = this.scene.add.group();

    //Items
    for(let i = 0; i < 20; i++) {
      this.numbers.createFromConfig({
        classType:ScoreNumber,
        key:'scoreNumbers',
        setXY:{x:0, y:0},
        active:false,
        visible:false
      });
    }
  }


  //-----------------------------------------------
  // Show a number
  //-----------------------------------------------
  showNumber(x, y, value) {
    let item = this.numbers.getFirstDead(false);
    if (item) {
      item.show(x, y, value);
    }
  }


  //-----------------------------------------------
  // Show a number
  //-----------------------------------------------
  showExplosion(x, y, type, colour = 'green') {
    let item = this.explosions[type].getFirstDead(false);
    if (item) {
      item.show(x, y, colour);
    }
  }


  //-----------------------------------------------
  // Init Explosions
  //-----------------------------------------------
  initExplosions() {
    //Groups
    this.explosions = {small:this.scene.add.group(), big:this.scene.add.group()};

    //Items
    for(let i = 0; i < 10; i++) {
      //Small
      this.explosions.small.createFromConfig({
        classType:ExplosionSmall,
        key:'explosionSmall',
        active:false,
        visible:false
      });

      //Big
      this.explosions.big.createFromConfig({
        classType:ExplosionBig,
        key:'explosionBig',
        active:false,
        visible:false
      });
    }
  }


  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'particles') {
      //Emit some particles
      this.particles.emitters.getByName(data.id).emitParticleAt(data.x, data.y);

      //Explosion
      if(data.explosion !== undefined) {
        this.showExplosion(data.x, data.y, data.explosion, (data.explosionColour !== undefined) ? data.explosionColour : 'green');
      }
    } else if(data.method === 'score') {
      //Score display
      if(data.x !== undefined) {
        this.showNumber(data.x, data.y, data.value);
      }
    }
  }
}
