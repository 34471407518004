/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Helper Functions
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/

//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilties
import * as Constants from '@/utilities/constants';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
class _Helper {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
  }


  //-----------------------------------------------
  // Dictionary Fetch
  //-----------------------------------------------
  dictionaryFetch(id, upper = false, info = {}, quotes = false, index = -1) {
    //String
    let str = '';

    //Handle arrays etc.
    if(Constants.DICTIONARY[id]) {
      str = Constants.DICTIONARY[id];
      if(typeof(str) === 'object') {
        if(index === -1 || str[index] === undefined) {
          str = str[Math.floor(Math.random() * str.length)];
        } else {
          str = str[index];
        }
      }
    }

    //Change
    if(quotes && str !== '') { str = '"' + str + '"'; }
    if(upper) { str = str.toUpperCase(); }

    //Replacements for anything in the game info object)
    for(let i in info) {
      str = str.split('{' + i .toUpperCase() + '}').join(info[i]);
    }

    //Return
    return str;
  }

  //-----------------------------------------------
  // Deploy Mode
  //-----------------------------------------------
  deployMode() {
    return Constants.DEPLOY_MODE;
  }


  //-----------------------------------------------
  // Deploy Mode
  //-----------------------------------------------
  isTouchDevice() {
    return ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0) ? true : false;
  }


  //-----------------------------------------------
  // Ordinal Number Suffix
  //-----------------------------------------------
  ordinalSuffix(n) {
    return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
  }


  //-----------------------------------------------
  // String pad
  //-----------------------------------------------
  stringPad(str = '', length = 3, pad = '0', pre = true) {
    str = str.toString();
    while(str.length < length) {
      str = (pre) ? pad + str : str + pad;
    }
    return str;
  }
}

//Export as singleton
const Helper = new _Helper();
export default Helper;