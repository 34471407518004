/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Score Number
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import * as Constants from '@/utilities/constants';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class ScoreNumber extends Phaser.GameObjects.DynamicBitmapText {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene) {
    //Super
    super(scene, 0, 0, '8BitOperatorPlusBold', '10', 12);

    //Colour
    this.setTint(Constants.COLOURS.green);
    this.setOrigin(0.5, 0.5);

  }


  //-----------------------------------------------
  // Show
  //-----------------------------------------------
  show(x, y, value) {
    this.x = x;
    this.y = y;
    this.text = value;
    this.setActive(true);
    this.setVisible(true);
    this.setAlpha(1);
    this.depth = 999;

    //Tween
    this.scene.tweens.add({ targets: [this], y:'-=10', duration: 500, ease: 'Power2', repeat: 0, delay: 0});
    this.scene.tweens.add({ targets: [this], alpha:0, duration: 250, ease: 'Power2', repeat: 0, delay: 250, onComplete:function() { this.die(); }.bind(this)});
   
  }

  //-----------------------------------------------
  // Die
  //-----------------------------------------------
  die() {
    this.setActive(false);
    this.setVisible(false);
  }
}
