/*
*   @site           Lewis Blissett: Space Invaders Game
*   @function       Object: Player
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import * as Constants from '@/utilities/constants';
import Sounds from '@/utilities/sounds';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Player extends Phaser.Physics.Arcade.Sprite {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, x, y) {
    //References
    super(scene, x, y, 'flytrap');

    //Fire
    this.bulletLast = 0;
    this.type = 'player';
   
    //Pubsubs
    this.addPubSubs();

    //Add to scene and physics
    scene.add.existing(this);
    scene.physics.add.existing(this);

    //Collide with world
    this.body.collideWorldBounds = true;

    //Root
    this.rootPosition = {x:this.x, y:this.y};

    //Listen
    this.addPubSubs();
  }


  //-----------------------------------------------
  // Event listeners
  //-----------------------------------------------
  addPubSubs() {
    //State / Game events
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }


  //-----------------------------------------------
  // Fire
  //-----------------------------------------------
  fire(time) {
    //Animation
    this.play('snap');

    //Store the time
    this.bulletLast = time;

    //SFX
    Sounds.play('sfx-shoot', {volume:0.28});

    //Fire an event to be picked up by the bullet manager
    PubSub.publish('game', { method:'fire', type:'player', x:this.x, y:this.y});
  }


  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'state') {
      if(data.state === 'game-restart' || data.state === 'game-intro') {
        this.reset((data.state === 'game-intro') ? true : false);
      }
    }
  }


  //-----------------------------------------------
  // Reset
  //-----------------------------------------------
  reset(resetPosition = true) {
    

    //Reset pos on game start
    if(resetPosition && !this.active) {
      this.x = this.rootPosition.x;
      this.y = this.rootPosition.y;
    }


    //Revive and reposition
    this.setActive(true);
    this.setVisible(true);
    this.setVelocityX(0);

  }


  //-----------------------------------------------
  // Die
  //-----------------------------------------------
  die() {
    //Remove
    this.setActive(false);
    this.setVisible(false);
    this.setVelocityX(0);
    
    //Sound
    Sounds.play('sfx-death', {volume:0.5});
    
    let id = Math.floor(Math.random() * 2) + 1;
    Sounds.play('sfx-explosion' + id, {volume:0.3});
  }


  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update(state, keys, time) { //
    
    //Kill player speed
    this.setVelocityX(0);

    //Player interaction allowed
    if(state.indexOf('game-') === 0 && state !== 'game-over' && state !== 'game-over-complete' && state !== 'game-death' && this.active) {
      //Move
      if (keys.left.down) {
        this.setVelocityX(-Constants.GAME_CONFIG.playerSpeed);
      } else if (keys.right.down) {
        this.setVelocityX(Constants.GAME_CONFIG.playerSpeed);
      }

      //Fire
      if(keys.fire.down && time - this.bulletLast > Constants.GAME_CONFIG.playerShootDelay && state === 'game-on') {
        this.fire(time);
      }
    }
  }
}
